html #pharmacy-panel {
  font-family: 'Hanken Grotesk', sans-serif !important; }
  html #pharmacy-panel .contact-title {
    font-size: 3.0rem;
    font-weight: 400;
    color: #000000; }
  html #pharmacy-panel .page-content {
    background-color: var(--bs-white);
    min-height: calc(100vh - var(--navbar-height));
    margin-top: 100px; }
  html #pharmacy-panel .kpy-parent .card {
    border: 0; }
  html #pharmacy-panel textarea {
    border-radius: 0;
    border: 1px solid var(--bs-black); }
    html #pharmacy-panel textarea:focus {
      border-color: var(--bs-black);
      box-shadow: none; }
  html #pharmacy-panel input, html #pharmacy-panel select {
    border-radius: 0;
    height: 56px;
    border: 1px solid var(--bs-black); }
    html #pharmacy-panel input:focus, html #pharmacy-panel select:focus {
      border-color: var(--bs-black);
      box-shadow: none; }
  html #pharmacy-panel .css-811lzm-control {
    height: 56px;
    border: 1px solid var(--bs-black) !important;
    background-color: var(--bs-secondary-bg) !important; }
  html #pharmacy-panel .css-1ulxeju-control {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    height: 56px;
    border: 1px solid var(--bs-black) !important;
    appearance: none !important;
    border-radius: 0 !important; }
  html #pharmacy-panel .css-1hb7zxy-IndicatorsContaine {
    height: 56px !important; }
  html #pharmacy-panel .css-olqui2-singleValue {
    color: var(--bs-body-color) !important;
    height: 40px !important; }
  html #pharmacy-panel .css-qbdosj-Input {
    height: 50px !important; }
    html #pharmacy-panel .css-qbdosj-Input input {
      height: 42px !important; }
      html #pharmacy-panel .css-qbdosj-Input input:disabled {
        color: var(--bs-body-color) !important; }
  html #pharmacy-panel .css-1i2ta08-control {
    box-shadow: none !important; }
  html #pharmacy-panel .css-1i2ta08-control, html #pharmacy-panel .css-1i2ta08-control:hover, html #pharmacy-panel .css-1i2ta08-control:active {
    box-shadow: none;
    height: 56px;
    border: 1px solid var(--bs-black) !important; }
  html #pharmacy-panel .btn {
    margin-top: 1.5rem;
    background-color: var(--bs-black);
    color: var(--bs-white);
    border: 1px solid var(--bs-black);
    height: 56px;
    border-radius: 0;
    line-height: 40px; }
    html #pharmacy-panel .btn:hover {
      background-color: var(--bs-black); }
  html #pharmacy-panel .btn.btn-primary {
    background-color: var(--bs-black);
    color: var(--bs-white);
    border: 1px solid var(--bs-black); }
  html #pharmacy-panel .btn.btn-secondary {
    background-color: var(--bs-white);
    color: var(--bs-black);
    border: 1px solid var(--bs-black); }
  html #pharmacy-panel .btn-action {
    margin-top: 0;
    line-height: 10px; }
  html #pharmacy-panel .profile {
    width: 200px;
    height: 200px;
    background-color: var(--bs-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-top: -100px;
    position: relative; }
    html #pharmacy-panel .profile img {
      width: 150px; }
  html #pharmacy-panel .hero {
    position: relative;
    background-color: #000000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: calc(100vh - var(--navbar-height));
    padding-top: calc(15vh + 64px); }
    html #pharmacy-panel .hero.not-full {
      min-height: auto;
      padding-top: calc(10vh);
      padding-bottom: calc(10vh); }
    @media (max-width: 600px) {
      html #pharmacy-panel .hero {
        padding-top: calc(5vh); } }
    html #pharmacy-panel .hero .container .actions {
      float: right;
      align-self: end;
      text-align: end; }
    @media (max-width: 1024px) {
      html #pharmacy-panel .hero .container {
        display: block; }
        html #pharmacy-panel .hero .container .actions {
          float: none;
          max-width: 600px;
          align-self: center;
          text-align: center; } }
    html #pharmacy-panel .hero .hero-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
    html #pharmacy-panel .hero .hero-content {
      margin: 3rem 0; }
      @media (max-width: 1024px) {
        html #pharmacy-panel .hero .hero-content {
          width: 100%;
          text-align: center; } }
      html #pharmacy-panel .hero .hero-content .overline {
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 0.5rem; }
      html #pharmacy-panel .hero .hero-content .hero-title {
        color: var(--bs-black); }
      html #pharmacy-panel .hero .hero-content h1 {
        font-size: 2.5rem;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 2rem;
        max-width: 600px; }
        @media (max-width: 600px) {
          html #pharmacy-panel .hero .hero-content h1 {
            font-size: 2rem; } }
        html #pharmacy-panel .hero .hero-content h1.xl {
          font-size: 3.5rem;
          font-weight: 400; }
      html #pharmacy-panel .hero .hero-content h2 {
        font-size: 2rem !important;
        line-height: 1 !important;
        font-weight: 600;
        max-width: 600px; }
        @media (max-width: 600px) {
          html #pharmacy-panel .hero .hero-content h2 {
            font-size: 1.7rem !important; } }
      html #pharmacy-panel .hero .hero-content .hero-description {
        margin-top: 2rem;
        color: var(--bs-black);
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.5em;
        max-width: 534px;
        --container-widget-width: 534px;
        --container-widget-flex-grow: 0; }
        @media (max-width: 600px) {
          html #pharmacy-panel .hero .hero-content .hero-description {
            width: 100%;
            font-size: 1rem; } }
    html #pharmacy-panel .hero .hero-buttons-index {
      max-width: 534px;
      --container-widget-width: 534px;
      --container-widget-flex-grow: 0;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 5rem;
      right: 0; }
      @media (max-width: 820px) {
        html #pharmacy-panel .hero .hero-buttons-index {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: 1rem; } }
      html #pharmacy-panel .hero .hero-buttons-index .btn {
        margin-top: 1.5rem;
        background-color: var(--bs-black);
        color: var(--bs-white);
        border: 1px solid var(--bs-black);
        height: 56px;
        border-radius: 0;
        line-height: 46px; }
        html #pharmacy-panel .hero .hero-buttons-index .btn:hover {
          background-color: var(--bs-black); }
        @media (max-width: 820px) {
          html #pharmacy-panel .hero .hero-buttons-index .btn {
            width: 100%; } }
      html #pharmacy-panel .hero .hero-buttons-index .btn.outline {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        background-color: transparent;
        color: var(--bs-black);
        height: 56px;
        border-radius: 0;
        line-height: 46px;
        border: 1px solid var(--bs-black); }
        html #pharmacy-panel .hero .hero-buttons-index .btn.outline:hover {
          background-color: var(--bs-black);
          color: var(--bs-white); }
        @media (max-width: 820px) {
          html #pharmacy-panel .hero .hero-buttons-index .btn.outline {
            width: 100%; } }
      @media (max-width: 820px) {
        html #pharmacy-panel .hero .hero-buttons-index .btn + .btn {
          margin: 1rem 0 0; } }
  html #pharmacy-panel section.offers {
    margin-top: 5rem;
    background-color: #FBF8F5; }
  html #pharmacy-panel .container-offer .btn.btn-primary {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-color: transparent;
    color: var(--bs-black);
    height: 56px;
    padding: 0 1.5rem;
    border-radius: 0;
    line-height: 44px;
    border: 1px solid var(--bs-black); }
    html #pharmacy-panel .container-offer .btn.btn-primary:hover {
      background-color: var(--bs-black);
      color: var(--bs-white); }
  html #pharmacy-panel .offer-card-image {
    background-color: var(--bs-white);
    align-items: center; }
    html #pharmacy-panel .offer-card-image .overlay {
      color: var(--bs-white);
      padding: 1rem; }
      html #pharmacy-panel .offer-card-image .overlay .btn {
        margin-top: 1.5rem;
        background-color: var(--bs-black);
        color: var(--bs-white);
        border: 1px solid var(--bs-black);
        height: 56px;
        border-radius: 0;
        line-height: 44px; }
        html #pharmacy-panel .offer-card-image .overlay .btn:hover {
          background-color: var(--bs-black); }
  html #pharmacy-panel footer {
    padding: 2rem;
    background: #4f675b; }
    html #pharmacy-panel footer .footer-content {
      display: flex;
      align-items: center;
      align-content: space-between;
      color: var(--bs-white);
      justify-content: space-between; }
      @media (max-width: 820px) {
        html #pharmacy-panel footer .footer-content {
          display: block;
          align-items: center;
          align-content: center;
          text-align: center; } }
      html #pharmacy-panel footer .footer-content img {
        max-width: 200px;
        filter: invert(1); }
      html #pharmacy-panel footer .footer-content a {
        color: var(--bs-white);
        text-decoration: none;
        display: block;
        padding: 0.3rem; }
      @media (max-width: 820px) {
        html #pharmacy-panel footer .footer-content div {
          padding: 0.5rem; }
        html #pharmacy-panel footer .footer-content a {
          padding: 0.5rem; } }
      @media (min-width: 1050px) {
        html #pharmacy-panel footer .footer-content .footer-center {
          margin-left: -20rem;
          text-align: left; } }
      @media (min-width: 820px) {
        html #pharmacy-panel footer .footer-content .footer-center {
          padding-top: 3rem; } }
      html #pharmacy-panel footer .footer-content .footer-right .texts {
        margin-top: 1rem; }
      html #pharmacy-panel footer .footer-content .footer-right img {
        max-width: 6rem; }
      @media (min-width: 820px) {
        html #pharmacy-panel footer .footer-content .footer-right {
          margin-right: 2rem;
          text-align: right;
          max-width: 9rem; } }
    html #pharmacy-panel footer hr {
      margin: 1.5rem 0;
      border-color: var(--bs-white);
      border-top: 1px solid white;
      opacity: .5; }
    html #pharmacy-panel footer .footer-copy {
      width: 100%;
      text-align: center; }
      html #pharmacy-panel footer .footer-copy p {
        color: var(--bs-white) !important;
        margin-bottom: 0; }
  html #pharmacy-panel .recurso-download {
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column; }
    html #pharmacy-panel .recurso-download .recurso-body {
      min-height: 192px;
      padding: .75rem;
      display: flex;
      align-items: center; }
      html #pharmacy-panel .recurso-download .recurso-body img {
        max-width: 30%;
        margin-right: 15px; }
      html #pharmacy-panel .recurso-download .recurso-body h5 {
        font-weight: bold;
        text-decoration: underline; }
    html #pharmacy-panel .recurso-download .recurso-footer {
      width: 100%;
      background-color: #FAF8F7;
      padding: .75rem;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      html #pharmacy-panel .recurso-download .recurso-footer .btn-sm {
        height: auto;
        margin-top: 0; }
  html #pharmacy-panel .recurso-formacion {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25rem; }
    @media (max-width: 1024px) {
      html #pharmacy-panel .recurso-formacion {
        height: 20rem; } }
    @media (max-width: 1026px) {
      html #pharmacy-panel .recurso-formacion {
        height: 25rem; } }
    @media (max-width: 765px) {
      html #pharmacy-panel .recurso-formacion {
        height: 28rem; } }
    @media (max-width: 575px) {
      html #pharmacy-panel .recurso-formacion {
        height: 25rem; } }
    html #pharmacy-panel .recurso-formacion img {
      width: 100%;
      height: 60%;
      border-radius: 5px;
      object-fit: cover; }
    html #pharmacy-panel .recurso-formacion .texts {
      height: 40%; }
    html #pharmacy-panel .recurso-formacion h4 {
      font-weight: 400;
      margin: .5rem 0; }
    html #pharmacy-panel .recurso-formacion .btn-sm {
      height: auto;
      line-height: 25px;
      margin-top: 0; }
  html #pharmacy-panel .contact-info h1 {
    font-size: 3.5rem;
    color: var(--bs-black); }
  html #pharmacy-panel .contact-info p {
    font-size: 22px;
    line-height: 34px; }
  html #pharmacy-panel .contact-info .contact {
    font-size: 16px;
    line-height: 26px; }
  html #pharmacy-panel .contact-info .bold {
    font-weight: bold; }
  html #pharmacy-panel .contact-info .font-bold {
    font-weight: bold; }
  html #pharmacy-panel .action-column, html #pharmacy-panel .actions-buttons {
    display: none; }
  html #pharmacy-panel .pharmacy > .card {
    border: 0; }

.section-title {
  color: #000000; }

btn-secondary {
  color: #000000 !important;
  background-color: #FFFFFF !important;
  border-color: #000000 !important; }

.resources-btn {
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /* Alinea los elementos a la derecha */
  gap: 10px;
  /* Espacio entre los botones */ }

.load-more {
  margin-bottom: 2rem; }
